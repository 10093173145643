//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Lifestyle_RoundsBar',
  props: {
    id: {
      type: String,
      default: '',
    },
    rounds: {
      type: Number,
      default: 0,
    },
  },
};

